<template>
  <swiper 
    :spaceBetween="30" 
    :centeredSlides="true" 
    :autoplay='{ "delay": 2500, "disableOnInteraction": false }' 
    :pagination='{ "clickable": true }' 
    :navigation="true" 
	:style="{
	  '--swiper-navigation-color': '#ff5a00',
	  '--swiper-pagination-color': '#ff5a00'
	}"
    class="mySwiper"
    >
	<swiper-slide v-for="(item,index) in bannerList" :key="index">
		<div class="banner">
			<img :src="item.resourceLink">
		</div>
	</swiper-slide>
  </swiper>
  <div class="m section1">
      <div class="main">
          <a class="bigTitle">
              <h2>公司简介</h2>
              <span>Profile</span>
          </a>
          <div class="conbox">
              <div class="videoBox">
                    <video controls="controls">
                        <source src="@/assets/qipa.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
              </div>
              <div class="aboutus">
				  <h4>{{ title }}</h4>
				  <div class="companyDesc" v-html="desc"></div>
              </div>
          </div>
      </div>
  </div>
  
  <Team />
  <News />
  <Games />
  <Partner />
  
</template>

<script setup>
// @ is an alias to /src
import Team from '@/components/home/Team.vue'
import News from '@/components/home/News.vue'
import Games from '@/components/home/Games.vue'
import Partner from '@/components/home/Partner.vue'

import { Swiper, SwiperSlide } from 'swiper/vue';

import SwiperCore, { Autoplay,Pagination,Navigation } from 'swiper/core';
SwiperCore.use([Autoplay,Pagination,Navigation]);

import { getPartDetail } from '@/api/index'
import { ref, onMounted, getCurrentInstance } from 'vue'

const { proxy } = getCurrentInstance(); 
const title = ref('关于奇葩游戏');
const desc = ref('');

const bannerList = ref([])

onMounted(()=>{//1公司简介
	getPartDetail({ ifPage: 0, moduleId: proxy.partten.moduleCompanyInfo }).then((res)=>{
		if(res.rows.length > 0){
			title.value = res.rows[0].title;
			desc.value = res.rows[0].textDescription;
		}
	})
	//14首页轮播图
	getPartDetail({ ifPage: 0, moduleId: proxy.partten.moduleBannerBig }).then((res)=>{
		bannerList.value = res.rows;
	})
})

</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
.mySwiper{
    width: 100%;height: 100vw;min-height: 260px;
	.banner{
		position: relative;width: 100%;height: 100%;overflow: hidden;
		img{
			height: 100%;position: absolute;
			top: 0;left: 50%;transform: translate(-50%,0);
		}
	}
}
.conbox{
    display: flex;align-items: center;font-size:14px;width:100%;flex-wrap: wrap;
	.videoBox{
		video{
			width: 100%;
		}
	}
    .aboutus,.videoBox{
        width: 100%;padding: 0 16px;box-sizing: border-box;
        h4{
            font-size: 16px;line-height: 32px;margin-top: 10px;margin-bottom: 15px;
        }
        p{line-height: 24px;margin-bottom: 15px;text-indent: 2em;}
    }
}
</style>
