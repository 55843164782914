<template>
<div class="section1 home" :class="{'m':isMobile()}">
      <div class="main">
          <a class="bigTitle">
              <h2>{{ moduleTitle }}</h2>
              <span>{{ moduleDesc }}</span>
          </a>
          <div class="conbox">
              <ul>
				  <template v-for="(item,index) in newsList" :key="index">
					  <li @click="toDetail(item)">
						  <div class="imgbox h156"><img :src="item.resourceLink" /></div>
						  <div class="desbox">
							  <p v-if="item.timeConf">{{ parseTime(item.timeConf,'{y}-{m}-{d}') }}</p>
							  <p v-else>{{ parseTime(item.createTime,'{y}-{m}-{d}') }}</p>
							  <h4>{{ item.title }}</h4>
							  <div class="descDots">{{ item.titleDescription }}</div>
						  </div>
					  </li>
				  </template>
              </ul>
          </div>
      </div>
  </div>
</template>

<script setup>
	import { isMobile } from '@/utils/common.js'
	import { getPartDetail } from '@/api/index'
	import { ref, onMounted, reactive } from 'vue'
	import { useRouter } from 'vue-router'
	
	let router = useRouter();
	let moduleTitle = ref('新闻动态')
	let moduleDesc = ref('News')
	let newsList = ref([])//大事件4
	let queryParams = reactive({
		moduleId:4,
		isHomePageConf: 1,//首页显示
		ifPage: 1,
		pageNum: 1,
		pageSize: 3
	})
	
	onMounted(()=>{
		getPartDetail(queryParams).then((res)=>{
			newsList.value = res.rows;
			if(res.rows > 0){
				moduleTitle.value = res.rows[0].moduleName
				moduleDesc.value = res.rows[0].nameEnglish
			}
		})
	})
	
	const toDetail = (item) => {
		if(item.hyperLink){
			window.open(item.hyperLink)
		}else{
			router.push('/introduce/news-detail/'+item.id)
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>

</style>
