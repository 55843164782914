<template>
<div class="section2 teamBox" :class="{'m':isMobile()}">
	<div class="main">
		<a class="bigTitle">
			<h2>{{ moduleTitle }}</h2>
			<span>{{ moduleDesc }}</span>
		</a>
		<swiper
		  :spaceBetween="30" 
		  :centeredSlides="true" 
		  :autoplay='{ "delay": 5500, "disableOnInteraction": false }' 
		  :pagination='{ "clickable": true }' 
		  :navigation="false" 
		  :style="{
		    '--swiper-pagination-color': '#fff'
		  }"
		  class="mySwiper"
		  >
			<swiper-slide v-for="(item,index) in managerList" :key="index">
				  <div class="manager">
					  <div class="imgbox">
						  <img :src="item.resourceLink">
					  </div>
					  <div class="desbox">
						  <p class="jobTitle">{{ item.title }}</p>
						  <p>{{ filterHTML(item.textDescription) }}</p>
					  </div>
				  </div>
			</swiper-slide>
			<!-- <swiper-slide>
				  <div class="manager">
					  <div class="imgbox">
						  <img src="@/assets/images/manager_duanshufeng.png">
					  </div>
					  <div class="desbox">
						  <p class="jobTitle">段曙峰：技术总监</p>
						  <p>段曙峰，曾担任芒果TV安全中心高级经理，负责芒果TV技术安全工作、互联网直播工作等； 2012年以技术股份入股奇葩游戏，2013年到2017年兼任奇葩游戏旗下的SLG手机游戏研发团队总经理，开发了3款SLG手机游戏，独代合作方有畅游，游爱，咪咕，指点传媒。2017年下半年开始全面负责管理BT技术支持部和联合研发部两个部门，并提供专业的技术指导</p>
					  </div>
				  </div>
			</swiper-slide>
			<swiper-slide>
				  <div class="manager">
					  <div class="imgbox">
						  <img src="@/assets/images/manager_liuwei.png">
					  </div>
					  <div class="desbox">
						  <p class="jobTitle">刘蔚：BT业务VP</p>
						  <p>刘蔚，2015年入职奇葩游戏，专注游戏运营领域多年；2016年起负责公司的BT发行业务至今。拥有专业的BT发行经验，主导了包含作妖计、校花的贴身高手、古剑奇谭、风云2、女神联盟、超梦西游、万剑至尊、文明曙光、妖神记、无尽远征、射雕三部曲等多款BT行业现象级产品的发行工作。</p>
					  </div>
				  </div>
			</swiper-slide> -->
		</swiper>
	</div>
</div>

</template>


<script setup>
import { isMobile } from '@/utils/common.js'
import { Swiper, SwiperSlide } from 'swiper/vue';

import { getPartDetail } from '@/api/index'
import { ref, onMounted } from 'vue'

const moduleTitle = ref('高管介绍')
const moduleDesc = ref('Executive introduction')
const managerList = ref([])//高管2

onMounted(()=>{
	getPartDetail({ ifPage: 0, moduleId:2 }).then((res)=>{
		managerList.value = res.rows;
		if(res.rows > 0){
			moduleTitle.value = res.rows[0].moduleName
			moduleDesc.value = res.rows[0].nameEnglish
		}
	})
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.teamBox{
	background: url(~@/assets/images/team_bg.jpg) no-repeat;
	background-size: cover;color: #fff;
	.bigTitle{
		color: #fff;
		span{
			color: #ddd!important;
		}
	}
	&.m{
		.manager{
			padding: 0;
			.imgbox{
				width: 10rem;height: 10rem;
			}
			.desbox{
				padding: 0 16px 40px;width: 37.5rem;
				p{font-size: 0.75rem;line-height: 24px;}
				.jobTitle{
					font-size: 24px;line-height: 50px;margin: 0 auto 20px;
				}
			}
		}
	}
}
.manager{
	padding: 30px 0 30px 120px;
	width: 100%;display: flex;align-items: center;justify-content: center;flex-wrap: wrap;
	.imgbox{
		width: 15rem;height: 15rem;overflow: hidden;border-radius: 1.5rem;margin: 2rem 0;
		-webkit-transform: rotate(45deg);-moz-transform: rotate(45deg);-ms-transform: rotate(45deg);-o-transform: rotate(45deg);transform: rotate(45deg);
		img{
			width: 134%;margin-left: -17%;margin-top: -17%;
			-webkit-transform: rotate(-45deg);-moz-transform: rotate(-45deg);-ms-transform: rotate(-45deg);-o-transform: rotate(-45deg);transform: rotate(-45deg);
		}
	}
	.desbox{
		width: 40rem;text-align: center;padding: 0 16px 40px 100px;box-sizing: border-box;
		p{line-height: 36px;text-align: left;font-size: 1rem;}
		.jobTitle{
			font-size: 32px;line-height: 50px;border-bottom: 1px solid #ddd;margin: 20px auto;display: inline-block;font-weight: bold;
		}
	}
}
.teams{
	width: 100%;
	ul{
		display: flex;align-items: center;justify-content: space-between;width: 100%;
		li{
			width: 24%;box-shadow: 0px 0px 20px #ddd;background: #fff;border-radius: 8px;overflow: hidden;margin-top: 20px;text-align: center;
		}
	}
}
</style>
