<template>
<div class="section1" :class="{'m':isMobile()}">
	<div class="main">
		<a class="bigTitle">
			<h2>{{ moduleTitle }}</h2>
			<span>{{ moduleDesc }}</span>
		</a>
		<div class="partnerList">
			<ul>
				<li v-for="(item,index) in partnerList" :key="index">
					<div class="borderBox">
						<img :src="item.resourceLink" :alt="item.title">
					</div>
				</li>
				<!-- <li>
					<div class="borderBox">
						<img src="../../assets/images/partner_xingwan.jpg">
					</div>
				</li>
				<li>
					<div class="borderBox">
						<img src="../../assets/images/partner_yinhan.jpg">
					</div>
				</li>
				<li>
					<div class="borderBox">
						<img src="../../assets/images/partner_youai.jpg">
					</div>
				</li>
				<li>
					<div class="borderBox">
						<img src="../../assets/images/partner_youyan.jpg">
					</div>
				</li>
				<li>
					<div class="borderBox">
						<img src="../../assets/images/partner_youzoo.jpg">
					</div>
				</li>
				<li>
					<div class="borderBox">
						<img src="../../assets/images/partner_zqbao.jpg">
					</div>
				</li>
				<li>
					<div class="borderBox">
						<img src="../../assets/images/partner_oppo.jpg">
					</div>
				</li>
				<li>
					<div class="borderBox">
						<img src="../../assets/images/partner_huawei.jpg">
					</div>
				</li>
				<li>
					<div class="borderBox">
						<img src="../../assets/images/partner_uc.jpg">
					</div>
				</li> -->
				<!-- <li>
					<div class="borderBox">
						<img src="../../assets/images/partner_zqbao.jpg">
					</div>
				</li>
				<li>
					<div class="borderBox">
						<img src="../../assets/images/partner_oppo.jpg">
					</div>
				</li>
				<li>
					<div class="borderBox">
						<img src="../../assets/images/partner_huawei.jpg">
					</div>
				</li>
				<li>
					<div class="borderBox">
						<img src="../../assets/images/partner_uc.jpg">
					</div>
				</li> -->
			</ul>
		</div>
	</div>
</div>
</template>

<script setup>
import { isMobile } from '@/utils/common.js'
import { getPartDetail } from '@/api/index'
import { ref, onMounted } from 'vue'

const moduleTitle = ref('合作伙伴')
const moduleDesc = ref('Partner')
const partnerList = ref([])//合作5

onMounted(()=>{
	getPartDetail({ ifPage: 1, moduleId: 5, pageNum: 1, pageSize: 14 }).then((res)=>{
		partnerList.value = res.rows;
		if(res.rows > 0){
			moduleTitle.value = res.rows[0].moduleName
			moduleDesc.value = res.rows[0].nameEnglish
		}
	})
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.partnerList{
	width: 100%;
	ul{
		width: 100%;display: flex;align-items: center;justify-content: center;flex-wrap: wrap;
		padding-top: 30px;
		li{
			width: 20%;margin: 2.5rem 0;
			.borderBox{
				width: 7.875rem;height: 7.875rem;border: 0.3125rem solid #ff5a00;border-radius: 0.875rem;overflow: hidden;margin: 0 auto;
				-webkit-transform: rotate(45deg);-moz-transform: rotate(45deg);-ms-transform: rotate(45deg);-o-transform: rotate(45deg);transform: rotate(45deg);
				img{
					width: 100%;transition: all 0.3s linear;
					-webkit-transform: rotate(-45deg);-moz-transform: rotate(-45deg);-ms-transform: rotate(-45deg);-o-transform: rotate(-45deg);transform: rotate(-45deg);
				}
				&:hover{
				    img{width: 110%;margin-left: -5%;margin-top: -5%;}
				}
			}
			&:nth-child(6){
				margin-left: 0.3125rem;margin-top: -30px;
			}
			&:nth-child(7),&:nth-child(8),&:nth-child(9){
				margin-top: -30px;
			}
			&:nth-child(10),&:nth-child(11),&:nth-child(12),&:nth-child(13),&:nth-child(14){
				margin-top: -30px;
			}
		}
	}
	
}
.m{
	.partnerList{
		ul{
			padding-bottom: 3.75rem;padding-top: 0;
			li{
				width: 50%;margin: 2.5rem 0;
				&:nth-child(3n){
					margin-left: 0.3125rem;margin-top: -3.2rem;margin-bottom: -3.2rem;
				}
			}
		}
	}
	
}
</style>
